import React from "react"
import Bg from "../images/od_logo_hollow.png"

const NotFoundPage = () => (
  <div class="relative bg-yellow">
    <div class="relative pt-12 pb-16 sm:pb-24 lg:pb-24">
      <main class="mx-auto max-w-7xl px-4 ">
        <img class="w-1/5 mx-auto" src={Bg} alt="" />
        <div class="text-center">
          <h1 class="text-4xl tracking-tight font-extrabold text-red sm:text-5xl md:text-7xl">
            Oops that page doesn't exist!
          </h1>
          <a
            href="/"
            class="underline text-2xl tracking-tight font-extrabold text-black sm:text-5xl md:text-3xl"
          >
            Take me back home
          </a>
        </div>
      </main>
    </div>
  </div>
)

export default NotFoundPage
